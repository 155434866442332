<template>
  <div style="display: flex; flex-direction: row; align-items: end; margin: 5px; gap: 15px; flex-wrap: wrap; justify-content: space-between">
    <div style="display: flex; align-items: end; gap: 18px; flex-wrap: wrap;">  
      <div style="display: flex;align-items: center; gap:5px">
          <label style="margin: 0;" for="asignaciones">Asignaciones:</label>
          <input id="asignaciones" v-model="asignacionesCheck" type="checkbox" class="form-control">
        </div>    
      <div class="form-group" style="margin-bottom: 0;">
        <label for="fechainicio">Fecha inicio:</label>
        <input style="width: 150px;" id="fechainicio" v-model="fechaInicio" type="date" class="form-control">
      </div>
      <div class="form-group" style="margin-bottom: 0;">
        <label for="fechafin">Fecha fin:</label>
        <input style="width: 150px;" id="fechafin" v-model="fechaFin" type="date" class="form-control">
      </div>
      <div class="form-group" style="margin-bottom: 0;">
        <label for="estado">Estados pendientes:</label>
        <select style="width: 280px;" v-model="estadoseleccionado" id="estado" class="form-control">
          <option value="-1">Seleccione estado</option>
          <option value="">Todos</option>
          <option v-for="[id, nombre] in Object.entries(estadospendientes)" :key="id" :value="id">{{ nombre }}</option>
        </select>
      </div>
      <div class="form-group" style="margin-bottom: 0;">
        <label for="operario">Operario:</label>
        <select style="width: 230px;" v-model="operarioseleccionado" id="operario" class="form-control">
          <option value="-1">Seleccione operario</option>
          <option value="">Todos</option>
          <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
        </select>
      </div>
      <div  class="form-group" style="margin-bottom: 0;">
        <label for="especialidades">Especialidades:</label>
        <select style="width: 200px;" v-model="especialidadseleccionada" id="especialidades" class="form-control">
          <option value="-1">Seleccione especialidad</option>
          <option value="">Todas</option>
          <option v-for="especialidad in especialidades" :key="especialidad.id" :value="especialidad.id">{{ especialidad.nombre }}</option>
        </select>
      </div>
      <div>
        <button @click="cargarDatos()" class="btn btn-light" style="border: 1px solid grey;">Filtrar</button>
      </div>
    </div>
    <div style="margin-left: 22px;">
      <div class="row">
        <span>Nº Visitas Totales </span>
        <strong>&nbsp;{{ totalPuntos }}</strong>
      </div>
      <div class="row" style="align-items: end;">
        <img style="width: 30px;" src="@/assets/img/casa.png" title="Asignaciones">
        <span style="font-size: x-large;">{{ asignaciones.length }}</span>
        <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" title="Visitas por confirmar">
        <span style="font-size: x-large;">{{ visitas_confirmar.length }}</span>
        <img src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png" title="Visitas pendientes">
        <span style="font-size: x-large;">{{ visitas_pendientes.length }}</span>
        <img src="https://maps.google.com/mapfiles/ms/icons/green-dot.png" title="Visitas en domicilio">
        <span style="font-size: x-large;">{{ visitas_domicilio.length }}</span>
        <img src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" title="Visitas finalizadas">
        <span style="font-size: x-large;">{{ visitas_finalizadas.length }}</span>
      </div>
    </div>
  </div>
  <div class="row" style="height: 600px; margin: 0; flex-wrap: nowrap;">
    <div style="width: 100%;">        
      <div ref="myMapRef"  style="margin:0 10px; height: 100%"></div>
    </div>
  </div>
</template>
  
  <script>
  import { Loader } from '@googlemaps/js-api-loader'; // Importa Google Maps API Loader
  import { PwgsApi } from '../../../services/PwgsApi';
  import { createApp } from 'vue';
  import PlanningPopup from './PlanningPopup.vue';
  import PlanningPopupAsig from './PlanningPopupAsign.vue';
  
  export default {
    props:['id'],
    data() {
      return {
        asignacionesCheck:true,
        estadoseleccionado:-1,
        estadospendientes:[],
        operarioseleccionado: -1,
        especialidadseleccionada: -1,
        center: { lat: 39.4746227, lng: -0.3477796 },
        origin: { lat: 39.46975, lng: -0.37739 }, // Puntos de origen
        destination: { lat: 39.98975, lng: -0.04679 }, // Puntos de destino
        map: null,
        directionsService: null,
        directionsRenderer: null,
        apiKey: '', // Clave de la API de Google Maps
        visitas_confirmar: [], // Almacenar las visitas confirmadas
        visitas_pendientes: [], // Almacenar las visitas pendientes
        visitas_domicilio: [],
        visitas_finalizadas: [],
        asignaciones:[],
        gps_operarios_app: [],
        gps_operarios_vehiculo: [],
        coordenadas: [],
        totalPuntos:0,
        operarios: [],
        especialidades: [],
        fechaFin:'',
        fechaInicio:'',
        markers: [],
        puntos:[],
        google:'',
      };
    },
    methods: {
      fechaActual() {
        const hoy = new Date();
        this.fechaInicio = this.fechaing(hoy.toLocaleDateString());
        this.fechaFin = this.fechaing(hoy.toLocaleDateString());
      },
      fechaing(fecha) {
        const [dia, mes, año] = fecha.split("/");
        return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
      },

      fechaesp(fecha) {
        const [año, mes, dia] = fecha.split("-");
        return `${dia}/${mes}/${año}`;
      },  
      
      async cargarDatos() {
        const api = new PwgsApi();
        let subidadatos = { tipo: 'mapa' , id_servicio: this.id};
        const response = await api.post('google-maps-key', subidadatos);
        this.apiKey = response;

        if(this.fechaInicio == ''){
          this.fechaActual();
        }

        var body = {};
        this.puntos = [];
        body.fecha_inicio = this.fechaesp(this.fechaInicio);
        body.fecha_fin = this.fechaesp(this.fechaFin);
        console.log('idserv',this.$props.id);
        body.id_servicio = this.$props.id;
        if(this.operarioseleccionado !='-1'){
          body.id_operario = this.operarioseleccionado;
        }
        if(this.asignacionesCheck){
          body.asignaciones = 1;
        }
        if(this.estadoseleccionado !='-1'){
          body.id_estado_pendiente = this.estadoseleccionado;
        }
        if(this.especialidadseleccionada !='-1'){
          body.id_especialidad = this.especialidadseleccionada;
        }
  
        // Cargar las visitas
        this.puntos = await api.post('planning-pwgs/geografico', body);
        this.coordenadas = this.puntos.coordenadas;
        this.visitas_confirmar = this.puntos.visitas_confirmar || [];
        this.asignaciones = this.puntos.asignaciones || [];
        this.visitas_pendientes = this.puntos.visitas_pendientes || [];
        this.visitas_domicilio = this.puntos.visitas_domicilio || [];
        this.visitas_finalizadas = this.puntos.visitas_finalizadas || [];
        this.gps_operarios_app = this.puntos.gps_operarios_app || [];
        this.gps_operarios_vehiculo = this.puntos.gps_operarios_vehiculo || [];
        this.totalPuntos = this.visitas_domicilio.length+ this.visitas_finalizadas.length + this.visitas_pendientes.length + this.visitas_confirmar.length + this.asignaciones.length;
        console.log('coords', this.coordenadas);
  
        // Cargar Google Maps API y configurar el mapa
        const loader = new Loader({
          apiKey: this.apiKey,
          version: 'weekly',
        });
  
        // Espera a que la API de Google Maps se cargue antes de inicializar el mapa
        loader.load().then((google) => {
          this.initMap(google);
        }).catch((err) => {
          console.error('Error al cargar Google Maps', err);
        });
      },
  
      initMap(google) {
        var center = {lat: parseFloat(this.coordenadas.split(',')[0]), lng: parseFloat(this.coordenadas.split(',')[1])};
        console.log('latlng', center);
        // Inicializa el mapa usando el objeto 'google' que fue pasado al resolver la promesa
        this.map = new google.maps.Map(this.$refs.myMapRef, {          
          center: center,
          zoom: 7,
        });

        // Coloca los marcadores para las visitas confirmadas y pendientes
        this.marcarVisitas(google);
        
        // Inicializa DirectionsService y DirectionsRenderer
        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
        map: this.map,
        });
        
      },
          
      irServicio(servicio) {
        
        localStorage.setItem('nombreservicio' + servicio.idservicios, servicio.codigo_servicio);

        this.$router.push({
                name: 'Servicio',
                params: {
                    id:servicio.idservicios
                }
            });
      },

      marcarVisitas(google) {
        this.routeInfo = null;
        this.google = google;

        this.markers.forEach(marker => marker.setMap(null));
        this.markers = []; // Vaciar el array de marcadores

        const iconSize = new google.maps.Size(40, 40); // Tamaño del icono
        const iconScaledSize = new google.maps.Size(40, 40); // Tamaño escalado del icono
        
        // Iconos personalizados para los marcadores
        const iconoCasa = {
            url: require('@/assets/img/casa.png'), // Icono casa
            scaledSize: iconSize // Ajusta el tamaño del icono
        };
        const iconoRojo = {
            url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // Icono rojo
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoAmarillo = {
            url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png', // Icono amarillo
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoAzul = {
            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Icono azul
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoVerde = {
            url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png', // Icono verde
            scaledSize: iconScaledSize // Ajusta el tamaño del icono
        };
        const iconoPersona = {
            url: require('@/assets/img/persona.png'), // Icono personalizado para operarios (tamaño grande)
            scaledSize: iconSize // Ajusta el tamaño del icono
        };
        const iconoCoche = {
            url: require('@/assets/img/coche.png'), // Icono personalizado para vehículos (tamaño grande)
            scaledSize: iconSize // Ajusta el tamaño del icono
        };
        const createPolylineFromGPSData = (gpsData, map) => {
          const routeCoordinates = gpsData.map((punto) => ({
            lat: parseFloat(punto.latitud_gps),
            lng: parseFloat(punto.longitud_gps),
          }));

          // Crea la polilínea con el color morado
          const routePath = new google.maps.Polyline({
            path: routeCoordinates,
            geodesic: true,
            strokeColor: '#800080', // Color morado
            strokeOpacity: 1.0,
            strokeWeight: 4,
          });

          // Dibuja la polilínea en el mapa
          routePath.setMap(map);
          console.log('acabaPuntos', this.map)
        };
        console.log('puntosAcs', this.puntos);
        try{
          if (this.puntos.gps_dia_app_operario.length>0) {
            console.log('puntosEntra');
            const gpsData = this.puntos.gps_dia_app_operario;
            createPolylineFromGPSData(gpsData, this.map);
          }
        }catch(e){
          console.log();
        }

        const createMarkerWithPopup = (position, map, icon, popupContent, infoContent) => {
          const marker = new google.maps.Marker({
            position,
            map,
            icon,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: popupContent,
          });

          const infoDialog = new google.maps.InfoWindow({
            content: infoContent,
          });

          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });
          marker.addListener('mouseover', () => {
            infoDialog.open(map, marker);
          });
          marker.addListener('mouseout', () => {
            infoDialog.close();
          });
          

          return marker;
        };

        // Agregar marcadores para las visitas con popups personalizados
        const addVisitMarkerWithPopup = (visita, icon, map) => {
          const [lat, lng] = visita.coordenadas_servicio.split(',').map(Number);
          
          const popupContent = document.createElement('div');
          const popupApp = createApp(PlanningPopup, {
            visita,
            onIrServicio: (visitaData) => {
              this.irServicio(visitaData);
            }
          });
          
          popupApp.mount(popupContent);
          
          let infoContent = '';
          if(visita.operario_nombre){
            infoContent = `<p>${visita.operario_nombre}</p>`;
          }else{
            infoContent = `<p>${visita.nombre}</p>`;
          }
          createMarkerWithPopup({ lat, lng }, map, icon, popupContent, infoContent);
        };        

        const addVisitMarkerWithPopupAsig = (visita, icon, map) => {
          const [lat, lng] = visita.coordenadas_servicio.split(',').map(Number);
          
          const popupContent = document.createElement('div');
          const popupApp = createApp(PlanningPopupAsig, {
            visita,
            onIrServicio: (visitaData) => {
              this.irServicio(visitaData);
            }
          });
          
          popupApp.mount(popupContent);
          
          let infoContent = '';
          if(visita.operario_nombre){
            infoContent = `<p>${visita.operario_nombre}</p>`;
          }else{
            infoContent = `<p>${visita.nombre}</p>`;
          }
          createMarkerWithPopup({ lat, lng }, map, icon, popupContent, infoContent);
        };    
        

        // Visitas asignaciones (icono morado)
        if (this.asignaciones) {
          this.asignaciones.forEach(visita => {
            addVisitMarkerWithPopupAsig(visita, iconoCasa, this.map);
          });
        }
        // Visitas confirmadas (icono rojo)
        if (this.visitas_confirmar) {
          this.visitas_confirmar.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoRojo, this.map);
          });
        }

        // Visitas pendientes (icono amarillo)
        if (this.visitas_pendientes) {
          this.visitas_pendientes.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoAmarillo, this.map);
          });
        }

        // Visitas finalizadas (icono azul)
        if (this.visitas_finalizadas) {
          this.visitas_finalizadas.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoAzul, this.map);
          });
        }

        // Visitas a domicilio (icono verde)
        if (this.visitas_domicilio) {
          this.visitas_domicilio.forEach(visita => {
            addVisitMarkerWithPopup(visita, iconoVerde, this.map);
          });
        }

        // Marcadores para operarios en aplicación y vehículos (personas y coches)
        if (this.gps_operarios_app) {
          this.gps_operarios_app.forEach(visita => {
            const lat = parseFloat(visita.latitud_gps);
            const lng = parseFloat(visita.longitud_gps);
            let popupContent = `          
              <div style="width:300px; margin:8px">            
                <div class="row" style="align-items:center">
                  <i class="fas fa-user-cog"></i>
                  <p style="margin:0">&nbsp;${visita.operario_nombre}</p>`;

            // Condicionales en JavaScript en lugar de v-if
            if (visita.operario_telefono1 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono1}</p>`;
            }

            if (visita.operario_telefono2 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono2}</p>`;
            }
            let infoContent = `<p>Ubicación del operario</p>`;
            createMarkerWithPopup({ lat, lng }, this.map, iconoPersona, popupContent, infoContent);
          });
        }

        if (this.gps_operarios_vehiculo) {
          this.gps_operarios_vehiculo.forEach(visita => {
            const lat = parseFloat(visita.latitud_gps);
            const lng = parseFloat(visita.longitud_gps);
            let popupContent = `          
              <div style="width:300px; margin:8px">            
                <div class="row" style="align-items:center">
                  <i class="fas fa-user-cog"></i>
                  <p style="margin:0">&nbsp;${visita.operario_nombre}</p>`;

            // Condicionales en JavaScript en lugar de v-if
            if (visita.operario_telefono1 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono1}</p>`;
            }

            if (visita.operario_telefono2 !== '0') {
              popupContent += `<p style="margin:0">&nbsp;- ${visita.operario_telefono2}</p>`;
            }     
            let infoContent = `<p>Ubicación del vehículo del operario</p>`;
            createMarkerWithPopup({ lat, lng }, this.map, iconoCoche, popupContent, infoContent);
          });
        }
      },       

      async obtenerOperarios() {
        const api = new PwgsApi();
        const response = await api.get('operarios/simple?sortField=nombre&sortOrder=1');
        this.operarios = response.datos;
      },

      async obtenerEstados() {
        const api = new PwgsApi();
        const response = await api.get('listados-servicios/estados-pendientes');
        this.estadospendientes = response.datos;
      },

      async obtenerEspecialidades() {
        const api = new PwgsApi();
        const response = await api.get('especialidades');
        this.especialidades = response.datos;
      },
    },
  
    mounted() {
      this.cargarDatos(); // Cargar los datos y el mapa al montar el componente
      this.obtenerEspecialidades();
      this.obtenerOperarios();
      this.obtenerEstados();
    },
    watch:{
      id(){
        console.log('idserv2', this.id);
      }
    }
  };
  </script> 